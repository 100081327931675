import React from "react";
import ReactDOM from "react-dom";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import "./style.css";
import Home from "./views/home";
import NotFound from "./views/not-found";
import ApprovalPending from "./views/approval-pending";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/approval-pending",
    element: <ApprovalPending />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

ReactDOM.render(<App />, document.getElementById("app"));
