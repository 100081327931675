import React from "react";

import PropTypes from "prop-types";

import "./features1.css";

const Features1 = props => {
  return (
    <div
      className={`features17-layout349 thq-section-padding ${props.rootClassName} `}
    >
      <div className="features17-max-width thq-section-max-width">
        <div className="features17-content">
          <div className="features17-section-title">
            <span className="thq-body-small">{props.feature1Slogan}</span>
            <div className="features17-content1">
              <h2 className="thq-heading-2">{props.feature1Title}</h2>
              <p className="thq-body-large">{props.feature1Description}</p>
            </div>
          </div>
        </div>
        <div className="features17-image-container">
          <img
            src={props.feature1ImageSrc}
            className="features18-video "
            style={{
              width: "100%",
              objectFit: "cover",
              borderRadius: "var(--dl-radius-radius-imageradius)",
              display: "block",
              maxWidth: "480px",
              maxHeight: "255px",
              height: "auto",
              boxShadow: "7px 7px 0px 0px #444444",
              borderColor: "var(--dl-color-theme-neutral-dark)",
              borderWidth: "1px",
              borderRadius: "4px",
            }}
          ></img>
        </div>
      </div>
    </div>
  );
};

Features1.defaultProps = {
  feature1Title: "Curriculum Made Interactive",
  feature1ImageAlt: "Curriculum Made Interactive Illustration",
  feature1ImageSrc:
    "https://s3.ap-south-1.amazonaws.com/feyntouch.com/files/feature-1-image.png",
  videoSrc: "",
  feature1Slogan: "",
  rootClassName: "",
  feature1Description:
    "FeynTouch has a collection of textbook elements — images, tables, and text — turned into interactive simulations, animations, and infographics",
};

Features1.propTypes = {
  feature1Title: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  videoSrc: PropTypes.string,
  feature1Slogan: PropTypes.string,
  rootClassName: PropTypes.string,
  feature1Description: PropTypes.string,
};

export default Features1;
