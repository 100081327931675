import React, { useState, useCallback } from "react";

import { Helmet } from "react-helmet";
import { Analytics } from "@vercel/analytics/react";

import Navbar4 from "../components/navbar4";
import Hero from "../components/hero";
import Features1 from "../components/features1";
import Features2 from "../components/features2";
import Features3 from "../components/features3";
import ContactForm from "../components/contact-form";
import "./home.css";
import DemoSection from "../components/demo";

const Home = props => {
  const [hideNav, setHideNav] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  window.onscroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos < 120 || prevScrollPos > currentScrollPos) {
      setHideNav(false);
    } else {
      setHideNav(true);
    }
    setPrevScrollPos(currentScrollPos);
  };

  return (
    <>
      <div
        className="home-container"
        style={{
          background:
            "linear-gradient(90deg, rgba(208,226,249,1) 0%, rgba(176,212,254,1) 35%, rgba(156,200,252,1) 100%)",
          // "linear-gradient(90deg, rgba(188,215,248,1) 0%, rgba(158,201,253,1) 35%, rgba(117,175,245,1) 100%)",
          // "linear-gradient(142deg, rgba(230,238,247,1) 0%, rgba(135,187,250,1) 35%, rgba(92,160,242,1) 100%)",
        }}
      >
        <Helmet>
          <title>FeynTouch | SmartBoard App for CBSE Teachers</title>
        </Helmet>
        <Navbar4
          rootClassName="navbar4-root-class-name"
          hide={hideNav}
        ></Navbar4>
        <Hero rootClassName="hero-root-class-name"></Hero>
        <div className="home-content">
          <div className="home-section-title">
            <h2 className="thq-heading-2">What's New?</h2>
          </div>
        </div>
        <Features1 rootClassName="features17-root-class-name"></Features1>
        <Features2 rootClassName="features18-root-class-name"></Features2>
        <Features3 rootClassName="features171-root-class-name"></Features3>
        <DemoSection rootClassName="demo-section-root-class-name"></DemoSection>
        <hr
          style={{
            width: "100%",
            boxShadow: "0px 1px 1px 1px #e6eef755",
            margin: "50px 0px",
          }}
        ></hr>
        <ContactForm rootClassName="contact-form-root-class-name"></ContactForm>
      </div>
      <Analytics />
    </>
  );
};

export default Home;
