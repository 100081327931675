import React from "react";

import PropTypes from "prop-types";

import "./hero.css";

const Hero = props => {
  return (
    <div
      className={`hero-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="hero-max-width thq-section-max-width">
        <div className="hero-column">
          <h1
            className="hero-text thq-heading-1"
            style={{ fontFamily: "Nunito" }}
          >
            {props.heading1}
          </h1>
          <p className="hero-text1 thq-body-large">{props.content1}</p>
          <div className="hero-actions">
            <a
              href="https://demo.feyntouch.com/"
              target="_blank"
            >
              <button className="thq-button-filled hero-button">
                <span className="thq-body-small">{props.action1}</span>
              </button>
            </a>
          </div>
        </div>
        <video
          src={props.video1Src}
          loop={true}
          muted={true}
          poster={props.video1Poster}
          autoPlay={true}
          className="hero-video thq-img-ratio-4-3"
        ></video>
      </div>
    </div>
  );
};

Hero.defaultProps = {
  action1: "Get Free Access",
  action2: "",
  video1Poster: "",
  content1:
    "Elevate your teaching with interactive tools designed to enhance student engagement and simplify lesson delivery",
  video1Src:
    "https://s3.ap-south-1.amazonaws.com/feyntouch.com/files/hero-video-v3-mute.mp4",
  rootClassName: "",
  heading1: "The best way to Teach on your SmartBoard",
};

Hero.propTypes = {
  action1: PropTypes.string,
  action2: PropTypes.string,
  video1Poster: PropTypes.string,
  content1: PropTypes.string,
  video1Src: PropTypes.string,
  rootClassName: PropTypes.string,
  heading1: PropTypes.string,
};

export default Hero;
