import React from "react";

import PropTypes from "prop-types";

import "./contact-form.css";

const ContactForm = props => {
  return (
    <div
      className={`contact-form-contact5 thq-section-padding ${props.rootClassName}`}
    >
      <div className="thq-flex-row thq-section-max-width contact-form-max-width">
        <div className="thq-flex-column contact-form-content">
          <div className="contact-form-section-title">
            <h2 className="thq-heading-2">{props.heading1}</h2>
            <span className="thq-body-large">{props.content1}</span>
          </div>
          <div className="contact-form-contact-info thq-flex-column">
            <div className="contact-form-row thq-flex-row">
              <svg
                viewBox="0 0 1024 1024"
                className="thq-icon-small"
              >
                <path
                  d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"
                  className=""
                ></path>
              </svg>
              <span className="thq-body-small">{props.email}</span>
            </div>
            <div className="contact-form-row1 thq-flex-row">
              <span className="thq-body-small">{props.phone}</span>
            </div>
            <div className="contact-form-row2 thq-flex-row">
              <span className="thq-body-small">{props.adress}</span>
            </div>
          </div>
        </div>
        <form className="contact-form-form">
          <div className="contact-form-input">
            <label
              htmlFor="contact-form-2-name"
              className="thq-body-small"
            >
              Name
            </label>
            <input
              type="text"
              id="contact-form-2-name"
              required={true}
              className="contact-form-text-input thq-input"
            />
          </div>
          <div className="contact-form-input1">
            <label
              htmlFor="contact-form-2-email"
              className="thq-body-small"
            >
              Email
            </label>
            <input
              type="email"
              id="contact-form-2-email"
              required={true}
              className="contact-form-text-input1 thq-input"
            />
          </div>
          <div className="contact-form-input2">
            <label
              htmlFor="contact-form-2-message"
              className="thq-body-small"
            >
              Message
            </label>
            <textarea
              id="contact-form-2-message"
              rows="3"
              className="contact-form-textarea thq-input"
            ></textarea>
          </div>
          <button
            type="submit"
            className="thq-button-filled"
          >
            <span className="thq-body-small">{props.action}</span>
          </button>
        </form>
      </div>
    </div>
  );
};

ContactForm.defaultProps = {
  content2:
    "Fill out the form below and we'll get back to you as soon as possible.",
  email: "Email: brijesh@feyntouch.com",
  phone: "",
  adress: "",
  heading1: "Contact Us",
  content1: "Have a question or want to learn more about FeynTouch?",
  rootClassName: "",
  action: "Submit",
};

ContactForm.propTypes = {
  content2: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  adress: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  rootClassName: PropTypes.string,
  action: PropTypes.string,
};

export default ContactForm;
