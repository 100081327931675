import React from "react";

import PropTypes from "prop-types";

import "./features3.css";

const Features3 = props => {
  return (
    <div
      className={`features171-layout349 thq-section-padding ${props.rootClassName} `}
    >
      <div className="features171-max-width thq-section-max-width">
        <div className="features171-content">
          <div className="features171-section-title">
            <span className="thq-body-small">{props.feature1Slogan}</span>
            <div className="features171-content1">
              <h2 className="thq-heading-2">{props.feature1Title}</h2>
              <p className="thq-body-large">{props.feature1Description}</p>
            </div>
          </div>
        </div>
        <div className="features171-image-container">
          <img
            alt={props.feature1ImageAlt}
            src={props.feature1ImageSrc}
            className="features18-video"
            style={{
              width: "100%",
              objectFit: "cover",
              borderRadius: "var(--dl-radius-radius-imageradius)",
              display: "block",
              display: "block",
              maxWidth: "480px",
              maxHeight: "255px",
              height: "auto",
              boxShadow: "7px 7px 0px 0px #444444",
              borderColor: "var(--dl-color-theme-neutral-dark)",
              borderWidth: "1px",
              borderRadius: "4px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

Features3.defaultProps = {
  feature1ImageSrc:
    "https://s3.ap-south-1.amazonaws.com/feyntouch.com/files/feature-3-image.jpg",
  rootClassName: "",
  feature1ImageAlt: "Innovative Teaching Tools Illustration",
  feature1Slogan: "",
  feature1Title: "Innovative Teaching Tools",
  feature1Description:
    "FeynTouch equips educators with cutting-edge tools like customizable coordinate systems and an infographic-diagram maker",
};

Features3.propTypes = {
  feature1ImageSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature1Slogan: PropTypes.string,
  feature1Title: PropTypes.string,
  feature1Description: PropTypes.string,
};

export default Features3;
