import React from "react";

import PropTypes from "prop-types";

import "./navbar4.css";

const Navbar4 = props => {
  return (
    <div
      className={`navbar4-container ${props.rootClassName}`}
      style={{
        top: props.hide ? "-120px" : "0",
      }}
    >
      <header
        data-thq="thq-navbar"
        className="navbar4-navbar-interactive"
        style={{
          fontFamily: "Nunito",
        }}
      >
        <img
          alt={props.logoAlt}
          src={props.logoSrc}
          className="navbar4-image1"
        />
        <div
          data-thq="thq-navbar-nav"
          className="navbar4-desktop-menu"
        >
          <nav className="navbar4-links">
            <span className="thq-link thq-body-small">{props.link1}</span>
            <span className="thq-link thq-body-small">{props.link4}</span>
            <span className="thq-link thq-body-small">{props.link5}</span>
          </nav>
          <div className="navbar4-buttons">
            <a
              href="https://demo.feyntouch.com/"
              target="_blank"
            >
              <button className="thq-button-filled">{props.action1}</button>
            </a>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="navbar4-burger-menu"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="navbar4-icon"
          >
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              className=""
            ></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="navbar4-mobile-menu"
        >
          <div className="navbar4-nav">
            <div className="navbar4-top">
              <img
                alt={props.logoAlt}
                src={props.logoSrc}
                className="navbar4-logo"
              />
              <div
                data-thq="thq-close-menu"
                className="navbar4-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="navbar4-icon2"
                >
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                    className=""
                  ></path>
                </svg>
              </div>
            </div>
            <nav className="navbar4-links1">
              <span className="thq-link thq-body-small">{props.link1}</span>
              <span className="thq-link thq-body-small">{props.link2}</span>
              <span className="thq-link thq-body-small">{props.link3}</span>
              <span className="thq-link thq-body-small">{props.link4}</span>
              <span className="thq-link thq-body-small">{props.link5}</span>
            </nav>
          </div>
          <div className="navbar4-buttons1">
            <button className="thq-button-filled">Login</button>
            <button className="thq-button-outline">Register</button>
          </div>
        </div>
      </header>
    </div>
  );
};

Navbar4.defaultProps = {
  link4: "About Us",
  rootClassName: "",
  link5: "Contact",
  action1: "Get Free Access",
  action2: "Log In",
  link1: "Home",
  link3: "Stories",
  logoSrc:
    "https://s3.ap-south-1.amazonaws.com/feyntouch.com/files/feyntouch-logo.svg",
  logoAlt: "FeynTouch",
  link2: "Articles",
};

Navbar4.propTypes = {
  link4: PropTypes.string,
  rootClassName: PropTypes.string,
  link5: PropTypes.string,
  action1: PropTypes.string,
  action2: PropTypes.string,
  link1: PropTypes.string,
  link3: PropTypes.string,
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
  link2: PropTypes.string,
};

export default Navbar4;
