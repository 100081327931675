import React from "react";
import { Helmet } from "react-helmet";

import "./approval-pending.css";

const ApprovalPending = props => {
  return (
    <div className="message-container">
      <Helmet>
        <title>FeynTouch - Approval Pending</title>
      </Helmet>
      <h1>Thank You for Signing Up!</h1>
      <p>
        FeynTouch is not yet officially released and is currently accessible to
        a <span class="important">very limited set of people</span>. Your
        registration will be approved within 24 hours, after which you'll be
        able to log in.
      </p>
    </div>
  );
};

export default ApprovalPending;
