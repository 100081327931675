import React from "react";

import PropTypes from "prop-types";

import "./hero.css";

const DemoSection = props => {
  return (
    <div
      className={`hero-container thq-section-padding ${props.rootClassName} `}
      style={{
        marginTop: "var(--dl-space-space-sixunits)",
      }}
    >
      <div
        className="hero-column"
        style={{
          gap: "var(--dl-space-space-oneandhalfunits)",
        }}
      >
        <div
          className="home-content"
          style={{ width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h4
              className="thq-heading-2"
              style={{ alignSelf: "center" }}
            >
              {props.heading1}
            </h4>
          </div>
        </div>
        <video
          id="demo-video"
          src={props.video1Src}
          controls={false}
          controlsList="nodownload"
          loop={false}
          muted={false}
          poster={props.video1Poster}
          autoPlay={false}
          className="hero-video thq-img-ratio-4-3"
          style={{ width: "100%", height: "100%" }}
          onClick={() => {
            const video = document.querySelector("#demo-video");
            video.controls = true;
          }}
        ></video>
        <h2
          className="thq-heading-2"
          style={{
            alignSelf: "center",
            marginTop: "var(--dl-space-space-oneandhalfunits)",
          }}
        >
          {props.heading2}
        </h2>
        <div
          className="hero-actions"
          style={{
            alignSelf: "center",
          }}
        >
          <a
            href="https://demo.feyntouch.com/"
            target="_blank"
          >
            <button className="thq-button-filled hero-button">
              <span className="thq-body-small">{props.action}</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

DemoSection.defaultProps = {
  heading1: "See This Clip",
  heading2: "Now Imagine... What You Can Do with FeynTouch!",
  action: "Get Free Access",
  rootClassName: "",
  video1Poster:
    "https://s3.ap-south-1.amazonaws.com/feyntouch.com/files/video-poster-1.png",
  video1Src:
    "https://s3.ap-south-1.amazonaws.com/feyntouch.com/files/ch10-s3-original+final-crisp.mp4",
};

DemoSection.propTypes = {
  heading1: PropTypes.string,
  heading2: PropTypes.string,
  action1: PropTypes.string,
  rootClassName: PropTypes.string,
  video1Poster: PropTypes.string,
  video1Src: PropTypes.string,
};

export default DemoSection;
